<template>
  <table class="table table-bordered">
    <thead class="thead-dark">
      <tr>
        <th class="text-center">#</th>
        <th>Name</th>
        <th class="text-center">Kurse</th>
        <th class="text-center">Kursplan-TV</th>
        <th class="text-center">Kundenstopper</th>
        <th class="text-center">Kursräume</th>
        <th class="text-center">Bearbeiten</th>
        <th class="text-center">Löschen</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td colspan="8">
          <router-link :to="{ name: 'studio-create' }"
            >+ Studio erstellen</router-link
          >
        </td>
      </tr>
      <tr v-for="studio in studios" :key="studio.id">
        <th class="text-center">{{ studio.nr }}</th>
        <td>{{ studio.name }}</td>
        <td class="text-center">
          <router-link
            :to="{ name: 'courses', params: { studioId: studio.id } }"
            >Kurse</router-link
          >
        </td>
        <td>
          <div class="d-flex">
            <div
              v-if="!courseRooms[studio.id].length"
              class="d-flex justify-content-center"
            >
              <a :href="kursplanBaseUrl + studio.id" target="_blank"
                >Kursplan-TV</a
              >
            </div>
            <select
              class="form-control form-control-sm"
              v-model="selectedCourseRoom"
              v-else
              @change="
                openTab(
                  kursplanBaseUrl +
                    studio.id +
                    '?courseRoomId=' +
                    selectedCourseRoom.id
                )
              "
            >
              <option :value="null">Kursraum auswählen...</option>
              <option
                v-for="courseRoom in courseRooms[studio.id]"
                :key="courseRoom.id"
                :value="courseRoom"
              >
                <span>{{ courseRoom.name }}</span>
              </option>
            </select>
          </div>
        </td>
        <td>
          <div class="d-flex justify-content-center">
            <a :href="kundenstopperBaseUrl + studio.id" target="_blank"
              >Kundenstopper</a
            >
          </div>
        </td>
        <td class="text-center">
          <router-link
            :to="{ name: 'studio-course-rooms', params: { id: studio.id } }"
            >Kursräume</router-link
          >
        </td>
        <td class="text-center">
          <router-link
            :to="{ name: 'studio-details', params: { id: studio.id } }"
          >
            <i class="fa fa-edit text-dark"></i>
          </router-link>
        </td>
        <td class="text-center" @click="remove(studio.id)">
          <i class="fa fa-trash cursor-pointer"></i>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { firestore, functions } from '../firebase';

const restartDevice = functions.httpsCallable('httpsRestartDevice');

export default {
  data() {
    return {
      studios: [],
      courseRooms: {},
      selectedCourseRoom: null,
      loading: false,
      kursplanBaseUrl: process.env.VUE_APP_KURSPLAN_BASE_URL,
      kundenstopperBaseUrl: process.env.VUE_APP_KUNDENSTOPPER_BASE_URL,
      restartingDevice: {},
    };
  },
  async created() {
    this.loading = true;

    const studiosSnapshot = await firestore
      .collection('studios')
      .orderBy('nr')
      .get();

    this.studios = studiosSnapshot.docs.map((snapshot) => {
      return {
        id: snapshot.id,
        ...snapshot.data(),
      };
    });

    this.studios.forEach((studio) => {
      this.courseRooms[studio.id] = [];
    });

    Object.keys(this.courseRooms).forEach(async (key) => {
      const courseRoomsSnapshot = await firestore
        .collection('studios')
        .doc(key)
        .collection('courseRooms')
        .get();

      courseRoomsSnapshot.docs.forEach((doc) => {
        this.courseRooms = {
          ...this.courseRooms,
          [key]: [
            ...this.courseRooms[key],
            {
              id: doc.id,
              ...doc.data(),
            },
          ],
        };
      });
    });

    this.loading = false;
  },
  methods: {
    openTab(href) {
      window.open(href, '_blank');
    },
    async remove(id) {
      if (confirm('Bist du sicher, dass du dieses Studio löschen möchtest?')) {
        await firestore.collection('studios').doc(id).delete();

        this.studios = this.studios.filter((studio) => studio.id !== id);
      }
    },
    async restartDevice(uuid) {
      if (!this.restartingDevice[uuid])
        if (confirm('Bist du sicher, das du dieses Gerät neustarten willst?')) {
          this.restartingDevice = {
            ...this.restartingDevice,
            [uuid]: true,
          };

          try {
            await restartDevice({ uuid });
          } catch (e) {
            alert('Gerät ist momentan nicht erreichbar');

            throw e;
          } finally {
            this.restartingDevice = {
              ...this.restartingDevice,
              [uuid]: false,
            };
          }
        }
    },
  },
};
</script>
